import { ErrorModel, ResultModel } from '../../models';
import { LoggerService, ReferenceService } from '../../services';
import { ACTION_CODE } from './_actionCode';
const FUNCTION_CODE = 'UPDATE_REFERENCE';

export async function updateReference(
    userData: Record<string, string>,
    updatedReference: Record<string, any>,
    id: string
) {
    try {

        LoggerService.logInfo('Action - updateReference - Start');

        const formatData = {
            ...updatedReference,
            context: updatedReference.context ? updatedReference.context.replace("<p><br></p>", '').replace("<br>", '<br><br>') : '',
            actions: updatedReference.actions ? updatedReference.actions.replace("<p><br></p>", '') : ''

        }

        const updateReferenceResult = await ReferenceService.updateReference(userData, formatData, id);

        LoggerService.logInfo('Action - updateReference - End - Success');

        return ResultModel.newSuccessResult(updateReferenceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - updateReference - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
