import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import ContainerCard from '../../../../components/ui/ContainerCard';
import SelectInput from '../../../../components/ui/Inputs/SelectInput';
import {frenchFormat, standardFormat, fetchSelectData} from '../../../../utils';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import Button from '../../../../components/ui/Button/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import {DealActions, UserActions, MissionActions} from '../../../../actions';
import Error from '../../../../components/ui/Error/Error';
import {getErrors} from '../../../../utils';
// import Tooltip from 'antd/lib/tooltip';
import TextInput from '../../../../components/ui/Inputs/TextInput';
// import deleteIcon from '../../../../assets/icons/actionsbutton/delete.png';
// import deleteIconBlue from '../../../../assets/icons/blueStyle/delete-blue.png';

const ProductionPopup = ({
    setView,
    dealData,
    refreshData,
    blueStyle
}) => {
    const navigate = useNavigate();

    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState();
    const [ressources, setRessources] = useState(null);

    const [totalInterventionDays, setTotalInterventionDays] = useState(0);
    const [totalBudget, setTotalBudget] = useState(0);

    // -- react hook form --
    const defaultValues = {
        production_lead: null,
        mission_end_date: null,
        mission_start_date: null
    };

    const [productionRessourcesValues, setProductionRessourcesValues ] = useState(
        [{id: null, mission: null, intervention_days: 1, daily_rate: 0, budget: 0}]
    );
    
    const {handleSubmit, control, reset, formState: {isValid}} = useForm({defaultValues: defaultValues});

    // -- fill invoice values in form for update --
    useEffect(() => {
        if (Object.keys(dealData).length) {
            const dealDataCopy = {...dealData};

            if (dealDataCopy.production_lead) {
                dealDataCopy.production_lead.name = `${dealDataCopy.production_lead.first_name} ${dealDataCopy.production_lead.last_name}`;
            }

            reset({
                production_lead: dealDataCopy.production_lead,
                mission_end_date: dealDataCopy.mission_end_date,
                mission_start_date: dealDataCopy.mission_start_date
            });
        }
    }, [dealData]);

    useEffect(() => {
        (async () => {
            try {
                if (!dealData.producers) return;
                // const paramString;
                const missionData = dealData.producers.map(async (producer) => {
                    const result = await MissionActions.getAllMissions(userData, `&ressource=${producer.username}&deal=${dealData.id}`, 1);

                    return result.data[0];
                })

                const missionDataResolve = await Promise.all(missionData);

                const newProductionRessourcesValues = missionDataResolve.map((mission) => {

                    const budget = parseInt((parseInt(mission.billable_days) * parseInt(mission.daily_rate)).toFixed(2));

                    return {
                        id: mission.id,
                        mission: mission,
                        intervention_days: parseInt(mission.billable_days),
                        daily_rate: parseInt(mission.daily_rate),
                        budget: budget
                    }
                });

                setProductionRessourcesValues(newProductionRessourcesValues)
            }
            catch (err) {
                console.error(err);
            }
        })();
    }, [dealData]);
    
    const userData = useSelector((state) => state.UserReducer.userProfileData);

    // -- function to fetch ressources dropdown values --
    const handleGetRessources = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setRessources, userData});
    };

    useEffect(() => {
        // ↓ ressources dropdown values
        (async () => {
            await handleGetRessources('',1);
        })();
    }, []);

    // --------------------------------------------
    // ↓ Dynamically calculate totals for Production Section ↓
    // --------------------------------------------
    useEffect(() => {

        const newTotalInterventionDays = productionRessourcesValues.reduce((acc, curr) => {
            const totalInterventionDays = acc + parseInt(curr.intervention_days ? curr.intervention_days : 0);

            return totalInterventionDays;
        }, 0);
        
        const newTotalBudget = productionRessourcesValues.reduce((acc, curr) => {
            const totalBudget = acc + parseInt(curr.budget ? curr.budget : 0);

            return totalBudget;
        }, 0);

        setTotalInterventionDays(newTotalInterventionDays);
        setTotalBudget(newTotalBudget);

    }, [productionRessourcesValues])

    const filterOutAlreadySelected = (options, selected) => {
        
        if (!options) return [];
        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.username === obj2?.mission?.ressource?.username
            )
        );

        return filteredArray;
    };

    // const handleAddProductionRessources = () => {
    //     if (productionRessourcesValues.length < 10) {
    //         setProductionRessourcesValues((prevState) => [...prevState, {id: Math.random(), mission: null, intervention_days: 0, daily_rate: 0, budget: 0}]);
    //     }
    // }

    // const handleRemoveProductionRessources = (id) => {
    //     setProductionRessourcesValues((prevState) => prevState.filter((element) => element.id !== id));
    // }

    const handleProductionRessourcesValueChange = (e, id, type) => {
        
        setProductionRessourcesValues((prevState) => prevState.map((el) => {
            if (el.id === id) {
                const updatedValue = { ...el, [type]: e };

                // Ensure the values are parsed correctly
                const intervention_days = parseInt(updatedValue.intervention_days) || 0;
                const daily_rate = parseInt(updatedValue.daily_rate) || 0;
                
                // Calculate the budget
                const budget = daily_rate * intervention_days || 0;

                // Return the updated object
                return { ...updatedValue, budget: parseInt(budget) || 0 };
            }

            return el;
        }));
    };

    // -- handler functions --
    const onSubmit = async (data) => {
        setDisabled(true);
        setLoading(true);
        setErrors();

        // ↓ body to send to the post/patch request (see API documentation)
        const dealProduction = {
            production_lead_id: data.production_lead.id,
            mission_end_date: standardFormat(data.mission_end_date),
            mission_start_date: standardFormat(data.mission_start_date)
        }

        const result = await DealActions.updateDeal(userData, dealProduction, dealData.id);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            const updateMissions = productionRessourcesValues.map(async (productionRessource) => {
                const missionPayload = {
                    id: productionRessource.mission.id,
                    billable_days: productionRessource.intervention_days,
                    daily_rate: productionRessource.daily_rate
                };
                const missionResult = await MissionActions.updateMissionById(userData, missionPayload);

                return missionResult;

            });

            const resolveMissions = await Promise.all(updateMissions);

            if (resolveMissions.some((mission) => !mission.success)) {
                setErrors(getErrors(resolveMissions));
                setLoading(false);
                setDisabled(false)

                return;
            }
            await refreshData();
            setView(false);
            notify('L\'affaire a bien été mise à jour');
        }

        setDisabled(false);
        setLoading(false);

        if (userData.role === "CO" && result.data.production_lead.id !== userData.userId)
            navigate('/deals', {replace: true})
    };

    const isACBC = dealData?.type === 'framework' || dealData?.type === 'purchase_order';

    return (
        <ContainerCard
            title={dealData.name}
            coloredButtonDisplayed={false}
            overflow='overflow-scroll'
        >
            <div className='w-full'>
                <div className="flex justify-start text-gradient w-[110%] pb-2 pl-2 mb-6 pt-4 border-b border-gray-200">
                Production
                </div>
                <div className="flex flex-row justify-between pt-2 pb-6">

                    <div className='flex flex-col'>
                        <div className="mb-1">
                            <Controller
                                name="production_lead"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez sélectionner une ressource'
                                }}}
                                render={({
                                    field: {onChange, value, ref},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        label='Responsable de production'
                                        placeholder='Responsable de production'
                                        inputRef={ref}
                                        value={value}
                                        error={error}
                                        options={ressources?.options}
                                        hasNextPage={ressources?.hasNextPage}
                                        loading={!ressources}
                                        onChange={onChange}
                                        isSearchable={true}
                                        fetchFunction={handleGetRessources}
                                        labelKeys={['first_name', 'last_name']}
                                        required={true}
                                        disabled={disabled}
                                        blueStyle={blueStyle}
                                    />
                                )}
                            />
                        </div>
                        <div className="mb-1">
                            <Controller
                                name="mission_start_date"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez sélectionner une date.'
                                }}}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <DateInput
                                        label={'Début de production'}
                                        onChange={onChange}
                                        value={frenchFormat(value)}
                                        error={error}
                                        required={true}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col justify-end'>
                        <div className="mb-1">
                            <Controller
                                name="mission_end_date"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez sélectionner une date.'
                                }}}
                                render={({
                                    field: {onChange, value},
                                    fieldState: {error}
                                }) => (
                                    <DateInput
                                        label={"Fin de production"}
                                        onChange={onChange}
                                        value={frenchFormat(value)}
                                        error={error}
                                        required={true}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </div>
                    </div>
                
                </div>
                <div className='w-full pb-4 border-b border-gray-200'>
                    {productionRessourcesValues.map((productionRessource, index) => {
                        return (
                            <div key={productionRessource.id} className='relative'>
                                <div className="w-full flex justify-between gap-4 mb-1">
                                    <div className="flex items-center">
                                        <SelectInput
                                            placeholder='Ressource'
                                            value={productionRessource.mission?.ressource}
                                            options={filterOutAlreadySelected(ressources?.options, productionRessourcesValues)}
                                            hasNextPage={ressources?.hasNextPage}
                                            loading={!ressources}
                                            onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource?.id, 'ressource')}
                                            isSearchable={true}
                                            fetchFunction={handleGetRessources}
                                            labelKeys={['first_name', 'last_name']}
                                            required={true}
                                            disabled={true}
                                            blueStyle={blueStyle}
                                        />
                                    </div>
                                    <div className='flex items-center'>
                                        <TextInput
                                            // width='w-[100px]'
                                            label=''
                                            onChange={(e) =>  handleProductionRessourcesValueChange(e, productionRessource.id, 'intervention_days')}
                                            value={productionRessource.intervention_days}
                                            required={true}
                                            disabled={isACBC ? true : disabled}
                                            placeholder="Jours d'intervention"
                                            type="number"
                                            // labelWidth='w-40'
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    J
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <TextInput
                                            width='w-[100px]'
                                            label=''
                                            onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource.id, 'daily_rate')}
                                            value={productionRessource.daily_rate}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="TJM"
                                            type="number"
                                            // labelWidth='w-40'
                                        />
                                        <label className=" font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <TextInput
                                            width='w-[200px]'
                                            label=''
                                            // onChange={(e) => handleProductionRessourcesValueChange(e, productionRessource.id, 'budget')}
                                            value={productionRessource.daily_rate * productionRessource.intervention_days}
                                            required={true}
                                            disabled={true}
                                            placeholder="Budget"
                                            type="number"
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                        </label>
                                    </div>
                                </div>
                                {/* <div className='absolute right-[-24px] top-[6px] flex justify-between'>
                                    {
                                        index === 0 ?
                                            null
                                            : <button
                                                onClick={() => handleRemoveProductionRessources(productionRessource.id)}
                                                disabled={disabled}
                                                className="flex items-center justify-center bg-transparent"
                                            >
                                                <Tooltip title='Supprimer'>
                                                    <div>
                                                        <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                    </div>
                                                </Tooltip>
                                            </button>
                                    }
                                </div> */}
                            </div>
                        )
                    })}
                    {/* <Tooltip title='Ajouter une ressource'>
                        <div
                            onClick={handleAddProductionRessources}
                            className={` cursor-pointer text-sm text-gradient ${productionRessourcesValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"} mt-1`}
                        >
                            <p className='hover:underline'>Ajouter une ressource</p>
                        </div>
                    </Tooltip> */}
                </div>
                <div className="w-full flex justify-between mb-1">
                    <div className="font-normal text-m text-[#646464] w-[250px]">
                    TOTAL
                    </div>
                    <div className="font-normal text-m text-[#646464] w-[250px]">
                        {totalInterventionDays} Jours
                    </div>
                    <div className="whitespace-pre-line font-normal text-m text-[#646464] w-[130px]">
                        {(totalBudget / totalInterventionDays ? totalBudget / totalInterventionDays : 0).toFixed(0)}€ HT
                (TJM moyen)
                    </div>
                    <div className="font-normal text-m text-[#646464] w-[235px]">
                        {totalBudget}€ HT
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => {
                            reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={'Mettre à jour'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default ProductionPopup;
