import { ErrorModel, ResultModel } from '../../models';
import { LoggerService, ReferenceService } from '../../services';
import { ACTION_CODE } from './_actionCode';
const FUNCTION_CODE = 'CREATE_REFERENCE';

export async function createReference(userData: Record<string, any>, payloadReference: any) {
    try {
        LoggerService.logInfo('Action - createReference - Start');

        const formatData = {
            ...payloadReference,
            context: payloadReference.context ? payloadReference.context.replace("<p><br></p>", '') : '',
            actions: payloadReference.actions ? payloadReference.actions.replace("<p><br></p>", '') : ''
        }

        const createReferenceResult = await ReferenceService.createReference(userData, formatData);

        LoggerService.logInfo('Action - createReference - End - Success');

        return ResultModel.newSuccessResult(createReferenceResult);
    }
    catch (error) {
        LoggerService.logInfo('Action - createReference - End - Error');

        const newErr = ErrorModel.newError({
            code: `${ACTION_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue.',
            error: error
        });
        LoggerService.logError(error);

        return ResultModel.newErrorResult(newErr);
    }
}
