import {ErrorModel} from '../../models';
import {DAO_CODE} from '../expenseDao/_daoCode';
import axios from 'axios';
import {URL} from '../root';
const FUNCTION_CODE = 'GET_ALL_EXPENSE_TYPES';

export async function getAllExpenseTypes(
    userToken: string,
    params: string,
    page: number,
) {
    try {
        const response = await axios.get(
            `${URL}/expense-types/?page=${page}&page_size=2000&order=name&active=true${params}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Token ${userToken}`
                }
            },
        );

        return response.data;
    }
    catch (error) {
        throw ErrorModel.newError({
            code: `${DAO_CODE}/${FUNCTION_CODE}/ERROR`,
            message: 'Une erreur est survenue lors de la récuperation de la liste des types de frais',
            error: error
        });
    }
}
