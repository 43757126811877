
import Badge from "../../../components/ui/Badge";
import ActionsButton from "../../../components/ui/ActionsButton";
import {getRafOverrun, getMonth} from "../../../utils";

export const missionsTableConfig = (handleRedirect, key) => {
    const missionsTableEdit = false;
    const blueStyle = false;

    return (
        {
            dropdown: false,
            dropdownLeftPadding: false,
            greyBackground: false,
            subline: true,
            columnConfig: [
                {
                    label: 'Ressource',
                    value: 'ressource',
                    width: 'w-[4%]',
                    sort: false,
                    component: (item) => {

                        return (
                            <div className="flex flex-row items-center justify-center w-full text-center">
                                <Badge content={[item.ressource]} type='round'/>
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'item',
                    sumLabel: ''
                },
                {
                    label: 'Budget',
                    value: 'budget',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {+item.budget}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: '€'
                },
                {
                    label: 'Jours',
                    value: 'billable_days',
                    width: 'w-[7.5%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {+item.billable_days}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'TJM',
                    value: 'daily_rate',
                    width: 'w-[7.5%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {+item.daily_rate}
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'Statut',
                    value: 'status',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        const missionStatus = () => {
                            switch (item.status) {
                                case 0:
                                    return {status: 'Prévue', color: '#f0a841'};
                                case 1:
                                    return {status: 'En cours', color: '#e36d38'};
                                case 2:
                                    return {status: 'Terminée', color: '#ced95f'};
                                default:
                                    return {status: 'Indéfini', color: '#212121'};
                            }
                        };

                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                <Badge content={missionStatus().status} color={missionStatus().color}/>
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                },
                {
                    label: 'RAF',
                    value: 'raf',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center text-center">
                                {+item.raf}
                            </div>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: getMonth(),
                    value: 'projection_m1',
                    key: 'projection_m1',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m1');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_m1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: getMonth(1),
                    value: 'projection_m2',
                    key: 'projection_m2',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m2');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_m2}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: getMonth(2),
                    value: 'projection_m3',
                    key: 'projection_m3',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_m3');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_m3}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Trimestre',
                    value: 'projection_q1',
                    key: 'projection_q1',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_q1');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_q1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Semestre',
                    value: 'projection_s1',
                    key: 'projection_s1',
                    width: 'w-[8%]',
                    sort: true,
                    component: (item, onChange, value) => {
                        const overrun = getRafOverrun(item, 'projection_s1');

                        return (
                            <>
                                {
                                    missionsTableEdit ?
                                        <div className="flex flex-row items-center justify-center px-3 text-center">
                                            <input
                                                type='number'
                                                value={value}
                                                onChange={onChange}
                                                className='w-full px-2 py-1 rounded-sm border-[1px] border-[#d9d9d9] focus:outline-none focus:border-[#ea8a3d]'
                                            />
                                        </div> :
                                        <div className={`flex flex-row items-center justify-center text-center ${overrun ? 'text-red-500' : ''}`}>
                                            {+item.projection_s1}
                                        </div>
                                }
                            </>
                        );
                    },
                    sumDisplayed: true,
                    sumBy: 'value',
                    sumLabel: 'jours'
                },
                {
                    label: 'Action',
                    value: null,
                    width: 'w-[5%]',
                    sort: false,
                    component: (item) => {
                        return (
                            <div className="flex flex-row items-center justify-center">
                                <ActionsButton
                                    type="details"
                                    isLoading={false}
                                    onClick={() => handleRedirect({type: 'mission', key}, item)}
                                    disabled={false}
                                    blueStyle={blueStyle}
                                />
                            </div>
                        );
                    },
                    sumDisplayed: false,
                    sumBy: '',
                    sumLabel: ''
                }
            ]
        }
    );
};
