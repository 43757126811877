import Tooltip from 'antd/lib/tooltip';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import deleteIcon from "../../../../assets/icons/actionsbutton/delete.png";
import plus from "../../../../assets/icons/button/plus.png";
import Button from '../../../../components/ui/Button/Button';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import CheckboxInput from '../../../../components/ui/Inputs/CheckboxInput/CheckboxInput';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import RichText from "../../../../components/ui/Inputs/RichText";
import SelectInput from '../../../../components/ui/Inputs/SelectInput';
import TagInput from '../../../../components/ui/Inputs/TagInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import { notify } from '../../../../components/ui/Toast/Toast';
import { fetchSelectData, frenchFormat, getErrors, standardFormat } from '../../../../utils';
import { ContractorActions, DealTagsActions, FileActions, LocationActions, ReferenceActions } from './../../../../actions';
import FileDragNDropInput from './../../../../components/ui/Inputs/FileDragNDropInput';

const CreateReferencePopup = ({userData, setView, reference, setReference, blueStyle}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [tags, setTags] = useState([]);
    const [tagsError, setTagsError] = useState(false);
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [cocontractors, setCocontractors] = useState([]);
    const [cocontractorsError, setCocontractorsError] = useState(false);
    const [cocontractorsSuggestions, setCocontractorsSuggestions] = useState([]);
    const [isCocontractorsAvailable, setIsCocontractorsAvailable] = useState(false);
    const [locations, setLocations] = useState(null);
    const [contactValues, setContactValues] = useState([
        {id: Math.random(), name: "", email: "", phone: ""}
    ]);
    const [contactInputsInvalidity, setContactInputsInvalidity] = useState(false);

    const defaultValues = {
        actions: null,
        budget: null,
        context: null,
        name: null,
        project_management: null,
        realisation_date: null,
        status: 'draft',
        tags: tags ? tags : [],
        title: null,
        file: null,
        location: ""
    }

    const {handleSubmit, control, reset, getValues, formState: {isValid}} = useForm({defaultValues: defaultValues});

    useEffect(() => {
        if (reference) {
            if (reference.contacts?.length > 0) {
                setContactValues(reference.contacts.map((contact) => {
                    return (
                        {
                            id: Math.random(),
                            name: contact.name,
                            email: contact.email,
                            phone: contact.phone
                        }
                    );
                }));
            }
            reset({
                actions: reference.actions,
                budget: reference.budget,
                context: reference.context,
                name: reference.name,
                project_management: reference.project_management,
                realisation_date: reference.realisation_date ? dayjs(reference.realisation_date) : null,
                status: reference.status,
                title: reference.title,
                location: {name: reference.location}
            });

            setCocontractors(reference.cocontractors.map((cocontractor) => ({...cocontractor, value: cocontractor.name, label: cocontractor.name})));
            setIsCocontractorsAvailable(reference.cocontractors.length);

            setTags(reference.tags.map((tag) => ({value: tag, label: tag})));
        }
    }, [reference]);

    const handleSetTagsSuggestions = async () => {
        const allTagsResult = await DealTagsActions.getAllDealTags(userData, '');

        if (allTagsResult.success) {
            setTagSuggestions(allTagsResult.data.results.map((tag) => ({value: tag.name, label: tag.name})));
        }
    }

    useEffect(() => {
        (async () => {
            const allContractorsResult = await ContractorActions.getAllContractors(userData);

            if (allContractorsResult.success) {
                setCocontractorsSuggestions(allContractorsResult.data.map((contractor) => ({...contractor, value: contractor.name, label: contractor.name})));
            }

            await handleSetTagsSuggestions();
        })();
    }, []);

    const handleGetLocations = async (search, page) => {
        await fetchSelectData({
            action: LocationActions.getAllLocation,
            search:`&search=${search}&is_active=true`,
            page: page,
            setter: setLocations,
            userData,
            resultPath: "data"
        });
    };

    // Gestion des contacts
    const handleContactValueChange = (e, id, type) => {
        setContactValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e}  : el));
    }
    
    const handleRemoveContact = (id) => {
        setContactValues((prevState) => prevState.filter((element) => element.id !== id));
    }
    
    const handleAddContact = () => {
        if (contactValues.length < 10) {
            setContactValues((prevState) => [...prevState, {id: Math.random(), name: "", email: "", phone: ""}]);
        }
    }

    const checkContactInputsValidity = () => {
        let invalid = false;
    
        for(const contact of contactValues) {
            if (contact.name.length < 1 || contact.email.length < 1 || contact.phone.length < 1) {
                invalid = true;
            }
        }
    
        if (contactValues.length < 1) invalid = true;
    
        setContactInputsInvalidity(invalid);
    }
    
    useEffect(() => {
        checkContactInputsValidity();
    }, [contactValues]);

    const onSubmitWithValidation = () => {
        handleSubmit((data) => {
            onSubmit(data, true)
        })();
        setTagsError(tags.length === 0);

        if (isCocontractorsAvailable) {
            setCocontractorsError(cocontractors.length === 0);
        }
    };

    const onSubmitWithoutValidation = () => {
        if (isCocontractorsAvailable && cocontractors.length === 0) {
            setCocontractorsError(true);
        }
        else {
            const data = getValues();

            onSubmit(data, false);
        }
    };

    const onSubmit = async (data, validation) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        if(isValid || !validation) {
            let file = null;

            if (data.file && data.file.uid && data.file.type) {
                const newFile = {
                    "type": data.file.type,
                    "name": data.file.name,
                    "file": data.file
                }

                const result = await FileActions.createFile(userData, newFile);

                if (!result.success) {
                    setErrors(getErrors(result));
                    setDisabled(false);
                    setLoading(false);

                    return;
                }
                else {
                    file = result.data;
                }
            }

            const contacts = contactValues.map((contact) => {
                return {
                    name: contact.name,
                    email: contact.email,
                    phone: contact.phone
                }
            });

            const payload = {
                tags: tags.map((tag) => tag.value),
                customer_logo: file ? file.id : reference.customer_logo,
                customer_logo_id: file ? file.id : reference.customer_logo_id,
                cocontractors_ids: cocontractors.map(cocontractor => cocontractor.id),
                location: data.location.name,
                contacts,
                status: validation ? 'published' : 'draft',
                name: data.name ? data.name : null,
                title: data.title ? data.title : null,
                realisation_date: data.realisation_date ? standardFormat(data.realisation_date) : null,
                budget: data.budget ? data.budget : null,
                project_management: data.project_management ? data.project_management : null,
                context: data.context ? data.context : null,
                actions: data.actions ? data.actions : null
            }

            const result = await ReferenceActions.updateReference(userData ,payload, reference.id);

            if (!result.success) {
                setErrors(getErrors(result));
            }
            else {
                await handleSetTagsSuggestions();
                setReference(result.data);
                notify(`La réference a été ${validation ? 'publiée' : 'modifiée'} avec succès.`);
                setView(false);
            }

            setDisabled(false);
            setLoading(false);
        }
    }

    const handleUpdateTags = (newValue) => {
        setTags(newValue);
    }

    const handleUpdateCocontractors = (newValue) => {
        setCocontractors(newValue);
    }

    return (
        <ContainerCard
            showHeader={false}
            coloredButtonDisplayed={false}
        >
            <div className='pb-6 mb-6 border-b border-gray-200'>
                <Controller
                    name="name"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir le nom de la référence'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInput
                            label='Nom'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Nom de la référence"
                            type="text"
                            width='w-[85.5%]'
                            color={blueStyle ? 'blue' : 'orange'}
                        />
                    )}
                />
            </div>
            <div className="flex flex-col h-auto">
                <div className='mb-3'>
                    <Controller
                        name="title"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le titre de la référence'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Titre'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Ajouter un titre"
                                type="text"
                                width='w-[85%]'
                                color={blueStyle ? 'blue' : 'orange'}
                            />
                        )}
                    />
                </div>
                <div className='flex justify-between mb-3'>
                    <Controller
                        name="project_management"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le maitrise d’ouvrage'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Maitrise d’ouvrage'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Maitrise d’ouvrage"
                                type="text"
                                width='w-72'
                                color={blueStyle ? 'blue' : 'orange'}
                            />
                        )}
                    />
                    <Controller
                        name="budget"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir le montant du budget'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <TextInput
                                label='Budget'
                                onChange={onChange}
                                value={value}
                                error={error}
                                required={true}
                                disabled={disabled}
                                placeholder="Budget"
                                type="number"
                                width='w-72'
                                color={blueStyle ? 'blue' : 'orange'}
                            />
                        )}
                    />
                </div>
                <div className='flex justify-between mb-3'>
                    <Controller
                        name="realisation_date"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez sélectionner une date de réalisation.'
                        }}}
                        render={({
                            field: {onChange, value},
                            fieldState: {error}
                        }) => (
                            <DateInput
                                label={'Date'}
                                onChange={onChange}
                                value={frenchFormat(value)}
                                error={error}
                                required={true}
                                disabled={disabled}
                                width='w-72'
                            />
                        )}
                    />
                    <Controller
                        name="location"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir un client'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                label='Département'
                                placeholder='Département'
                                inputRef={ref}
                                error={error}
                                value={value}
                                options={locations?.options}
                                hasNextPage={locations?.hasNextPage}
                                onChange={onChange}
                                fetchFunction={handleGetLocations}
                                labelKeys={['name']}
                                required={true}
                                disabled={disabled}
                                isSearchable={true}
                                width='w-72'
                                menuHeight='250px'
                            />
                        )}
                    />
                </div>
                <div className="flex items-start justify-between mb-3">
                    <div className="flex flex-row items-start justify-start">
                        <div className='w-36 mt-[9px]'>
                            <CheckboxInput
                                label='Cotraitants'
                                required={false}
                                onChange={() => setIsCocontractorsAvailable(!isCocontractorsAvailable)}
                                value={isCocontractorsAvailable}
                                color={blueStyle ? 'blue' : 'orange'}
                            />
                        </div>
                        <TagInput
                            onChange={handleUpdateCocontractors}
                            data={cocontractors}
                            suggestions={cocontractorsSuggestions}
                            error={cocontractorsError}
                            label=''
                            required={false}
                            labelStyle='text-sm text-[#646464] mr-0'
                            placeholder='Ajouter un cotraitant'
                            disabled={!isCocontractorsAvailable}
                            errorMessage='Veuillez sélectionner un cotraitant'
                            indicatorMessage={
                                isCocontractorsAvailable ?
                                    'Sélectionnez une option ou saisissez un nouveau<br/>cotraitant et appuyez sur entrer' : ''
                            }
                        />
                    </div>
                    <TagInput
                        onChange={handleUpdateTags}
                        data={tags}
                        suggestions={tagSuggestions}
                        error={tagsError}
                        required={true}
                        indicatorMessage='Sélectionnez une option ou saisissez un nouveau<br/>tag et appuyez sur entrer'
                    />
                </div>
                <div>
                    {
                        contactValues.map((contact, index) => {
                            return (
                                <div key={contact.id} className='flex justify-start mb-3.5 w'>
                                    <div>
                                        <TextInput
                                            label='Contact'
                                            onChange={(e) => handleContactValueChange(e, contact.id, 'name')}
                                            value={contact.name}
                                            error={null}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Nom"
                                            type="text"
                                            labelWidth='w-[140px]'
                                            customStyle='mr-4'
                                        />
                                    </div>
                                    <TextInput
                                        label=''
                                        onChange={(e) => handleContactValueChange(e, contact.id, 'email')}
                                        value={contact.email}
                                        error={null}
                                        required={true}
                                        disabled={disabled}
                                        placeholder="Email"
                                        type="text"
                                        labelWidth='w-14'
                                        customStyle='mr-4'
                                    />
                                    <TextInput
                                        label=''
                                        onChange={(e) => handleContactValueChange(e, contact.id, 'phone')}
                                        value={contact.phone}
                                        error={null}
                                        required={true}
                                        disabled={disabled}
                                        placeholder="Téléphone"
                                        type="text"
                                        width='w-36'
                                        labelWidth='w-24'
                                        customStyle='mr-10'
                                    />
                                    {
                                        index === 0 ?
                                            null
                                            : <button
                                                onClick={() => handleRemoveContact(contact.id)}
                                                disabled={disabled}
                                                className="flex items-center justify-center p-1 bg-transparent"
                                            >
                                                <Tooltip title='Supprimer'>
                                                    <div>
                                                        <img src={deleteIcon} alt="logo" className="w-[18px]"/>
                                                    </div>
                                                </Tooltip>
                                            </button>
                                    }
                                </div>
                            );
                        })
                    }
                    <div className='flex items-center mb-4'>
                        <button
                            onClick={handleAddContact}
                            disabled={!(contactValues.length < 10)}
                            className={`
                                p-2 rounded-full ${contactValues.length < 10 ? 'hover:opacity-90' : ''}
                                background-gradient ${!(contactValues.length < 10) ? 'opacity-[40%]' : 'opacity-100'}
                            `}
                        >
                            <img src={plus} alt='plus' className='w-6'/>
                        </button>
                        <div className={`font-normal text-sm ml-2 ${contactValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"}`}>
                            Ajouter un contact
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center mb-5">
                <Controller
                    name="file"
                    control={control}
                    rules={
                        reference ?
                            undefined
                            : {required: {
                                value: true,
                                message: 'Veuillez charger un logo'
                            }}}
                    render={({
                        field: {onChange}
                    }) => (
                        <FileDragNDropInput
                            onChange={onChange}
                            uploadText='Glissez déposez ou séllectionnez des fichiers “logos des clients”'
                            uploadHint="Ajoutez ici des documents (PDF, JPEG, PDF) lié à la référence"
                            maxCount={1}
                            color={blueStyle ? '#01abc5' : '#E36D38'}
                        />
                    )}
                />
            </div>
            <div>
                <p className='mb-1 text-sm'>Contexte & Objectifs <span className='text-red-500'>*</span></p>
                <Controller
                    name="context"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir le contexte & objectifs'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <RichText
                            onChange={onChange}
                            value={value}
                            error={error}
                            disabled={disabled}
                            placeholder="Contexte & Objectifs"
                            height='h-96'
                        />
                    )}
                />
            </div>
            <div className='mt-4'>
                <p className='mb-1 text-sm'>Actions & Méthodologie <span className='text-red-500'>*</span></p>
                <Controller
                    name="actions"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir l\'actions & méthodologie'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <RichText
                            onChange={onChange}
                            value={value}
                            error={error}
                            disabled={disabled}
                            placeholder="Actions & Méthodologie"
                            height='h-96'
                        />
                    )}
                />
            </div>
            <div className="flex flex-col justify-center pb-2 mt-8">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        onClick={onSubmitWithoutValidation}
                        type={'secondary'}
                        content={'Enregistrer'}
                        disabled={disabled}
                    />
                    <Button
                        type={`${blueStyle ? 'blue' : 'primary'}`}
                        content={`Publier la référence`}
                        onClick={onSubmitWithValidation}
                        loading={loading}
                        disabled={!isValid || tags.length === 0 || (isCocontractorsAvailable && cocontractors.length === 0) || contactInputsInvalidity}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default CreateReferencePopup;
