import React from 'react';

import plus from '../../../../assets/icons/button/plus.png';
import Button from '../../../../components/ui/Button';
import Table from '../../../../components/ui/Table';

const DealsDataSection = ({
    data,
    tableConfig,
    tableLoading,
    handleButtonClick,
    paginationMetadata,
    onTablePageChange
}) => {
    return (
        <div className='px-20 pt-12 pb-36'>
            <div className="flex items-center justify-between mb-8">
                <div className="text-4xl" style={{fontFamily: 'DIN Condensed'}}>
                    Les affaires
                    <span className="text-xs ml-1 text-[#646464]">
                        ({paginationMetadata?.totalItems})
                    </span>
                </div>
                <Button
                    content={"Nouvelle affaire"}
                    icon={plus}
                    onClick={handleButtonClick}
                />
            </div>
            <Table
                data={data}
                tableConfig={tableConfig}
                loading={tableLoading}
                paginationMetadata={paginationMetadata}
                onPageNumberChange={onTablePageChange}
            />
        </div>
    )
}

export default DealsDataSection;
