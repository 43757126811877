const roadStepDealsConfig = (isInternal, isTraining, dealData) => {
    
    const cannotBeModified = dealData.status === 900;
    const won = dealData.status >= 500 && !dealData.loss_motive;
    
    const lost = dealData.status >= 400 && dealData.loss_motive;

    //pass deal status and step status to function
    // return true if status less or equal to deal status
    //false otherwise
    // always true if deal status = 900

    // INTERNAL_DEAL = 0 (Activité interne)
    // OTHER_INTERNAL = 1 (Autres (TdA))
    // SPECIFICATIONS_REQUIRED = 100 (Cahier des Charges demandé)
    // SPECIFICATIONS_STUDY = 150 (Étude CdC)
    // OUT_OF_SCOPE = 190 (Abandonnée)
    // PROPOSAL_IN_PROGRESS = 200 (Réponse en cours)
    // ABANDONED = 290 (Abandonée)
    // PROPOSAL_DELIVERED = 300 (Offre remise)
    // LOST = 400 (Perdue)
    // WON = 500 (Gagnée)
    // PRODUCED = 600 (Produite)
    // BILLED = 700 (Facturée)
    // DONE = 900 (Close)

    const isStepDisabled = (step) => {
        if (dealData.status === 900) return true; // Cannot modify when deal is closed
    
        // Ordered list of step statuses
        const stepStatuses = [100, 150, 200, 300, 500, 600, 700, 900];
    
        // Find the index of the current status in the stepStatuses array
        let currentStepIndex = -1;
        for (let i = 0; i < stepStatuses.length; i++) {
            if (dealData.status >= stepStatuses[i]) {
                currentStepIndex = i;
            }
            else {
                break;
            }
        }
    
        // Find the index of the step in question
        const stepIndex = stepStatuses.indexOf(step);
    
        if (stepIndex === -1) {
            // Step not found in the list, disable it
            return true;
        }
    
        // Disable steps prior to current (cannot go back)
        if (stepIndex <= currentStepIndex) {
            return true;
        }
    
        // Disable steps beyond the immediate next step (cannot skip steps)
        if (stepIndex > currentStepIndex + 1) {
            return true;
        }
    
        // Enable only the immediate next step
        return false;
    };

    return (
        {
            color: isTraining ? 'blue' : 'orange',
            width: isInternal ? 'w-[40%]' : 'w-[85%]',
            items: isInternal ?
                [
                    { title: 'En cours', disabled: cannotBeModified },
                    { title: 'Terminée', disabled: cannotBeModified }
                ]
                :
                [
                    { title: 'CdC demandé', disabled: isStepDisabled(100) },
                    { title: 'Étude CdC', disabled: isStepDisabled(150) },
                    { title: dealData.status <= 150 ? "Résultat d'analyse" : "Réponse en cours", disabled: isStepDisabled(200) },
                    { title: 'Offre remise', disabled: isStepDisabled(300) },
                    { title: won ? 'Gagnée' : lost ? 'Perdue' : 'Résultat', disabled: isStepDisabled(500)},
                    { title: 'Produite', disabled: isStepDisabled(600) },
                    { title: 'Facturée', disabled: isStepDisabled(700) },
                    { title: 'Close', disabled: isStepDisabled(900) }
                ]
        }
    );
}

export default roadStepDealsConfig;
