export function getMonth(offset = 0) {
    const currentMonth = new Date().getMonth() + offset;
    
    const numberToMonthDictionary = {
        0: 'Janvier',
        1: 'Février',
        2: 'Mars',
        3: 'Avril',
        4: 'Mai',
        5: 'Juin',
        6: 'Juillet',
        7: 'Août',
        8: 'Septembre',
        9: 'Octobre',
        10: 'Novembre',
        11: 'Décembre'
    };

    if (currentMonth > 11) {
        return numberToMonthDictionary[currentMonth - 12];
    }

    return numberToMonthDictionary[currentMonth];
}