import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import dayjs from 'dayjs';
import Tooltip from 'antd/lib/tooltip';

import ContainerCard from '../../../../components/ui/ContainerCard';
import Button from '../../../../components/ui/Button';
import {notify} from '../../../../components/ui/Toast/Toast';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import {frenchFormat, standardFormat} from '../../../../utils';
import plus from '../../../../assets/icons/button/plus.png';
import {UserActions} from '../../../../actions';
import {DealActions} from '../../../../actions';
import Error from '../../../../components/ui/Error/Error';
import {getErrors, setCustomErrorMessage} from '../../../../utils';
import deleteIcon from '../../../../assets/icons/actionsbutton/delete.png';
import deleteIconBlue from '../../../../assets/icons/blueStyle/delete-blue.png';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import {fetchSelectData} from '../../../../utils';

const SalesPopup = ({
    setView,
    data,
    refreshData,
    blueStyle,
    setSalesPopupOverflow
}) => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [saleValues, setSaleValues] = useState([]);
    const [allUsers, setAllUsers] = useState(null);
    const [saleInputsInvalidity, setSaleInputsInvalidity] = useState(false);

    // -- react hook form --

    let defaultValues;

    if (data.status === 100) {
        defaultValues = {
            reception_date: null
        };
    }

    if (data.status === 150) {
        defaultValues = {
            reception_date: null,
            analysis_date: null
        };
    }

    if (data.status === 200) {
        defaultValues = {
            reception_date: null,
            analysis_date: null,
            max_response_date: null
        };
    }
    // defaultValues = {
    //     // chance: '',
    //     reception_date: null,
    //     analysis_date: null
    //     // response_date: null
    // };
    const {handleSubmit, watch, control, reset, formState, formState: {isValid}} = useForm({defaultValues: defaultValues});

    const successChanceData = [
        {
            id: 0,
            name: 'Inconnue'
        },
        {
            id: 1,
            name: 'Faible'
        },
        {
            id: 2,
            name: 'Moyenne'
        },
        {
            id: 3,
            name: 'Forte'
        }
    ]

    // -- static dropdown values --
    const statusData = [
        { id: 100, name: 'Cahier des charges demandé' },
        { id: 150, name: 'Étude du Cahier des charges' },
        { id: 200, name: 'Réponse en cours' },
        { id: 300, name: 'Offre remise' },
        { id: 500, name: 'Gagnée' }
    ];

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const handleGetAllUsers = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page, setter: setAllUsers, userData});
    };

    // Get dropdows data
    useEffect(() => {
        // ↓ users dropdown values
        (async () => {
            await handleGetAllUsers('', 1);
        })();
    }, [])

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            if (data.sales?.length > 0) {
                setSaleValues(data.sales.map((sale, index) => {
                    return (
                        {
                            id: `${sale.ressource?.id}${index}`,
                            percent: sale.percent,
                            ressource: sale.ressource
                        }
                    );
                }));
            }
            else {
                setSaleValues([{id: Math.random(), name: "", email: "", phone: ""}]);
            }
        }
    }, [data])

    useEffect(() => {
        
        if (Object.keys(data).length > 0) {
            if (data.status === 100) {
                reset({
                    reception_date: data.reception_date ? dayjs(data.reception_date) : null
                });
            }

            if (data.status === 150) {
                reset({
                    reception_date: data.reception_date ? dayjs(data.reception_date) : null,
                    analysis_date: data.analysis_date ? dayjs(data.analysis_date) : null
                });
            }

            if (data.status === 200) {
                reset({
                    reception_date: data.reception_date ? dayjs(data.reception_date) : null,
                    analysis_date: data.analysis_date ? dayjs(data.analysis_date) : null,
                    max_response_date: data.max_response_date ? dayjs(data.max_response_date) : null
                });
            }

            if (data.status >= 300) {
                reset({
                    reception_date: data.reception_date ? dayjs(data.reception_date) : null,
                    analysis_date: data.analysis_date ? dayjs(data.analysis_date) : null,
                    max_response_date: data.max_response_date ? dayjs(data.max_response_date) : null,
                    response_date: data.response_date ? dayjs(data.response_date) : null,
                    chance: successChanceData.filter((item) => item.id === data.chance)[0]
                });
            }

            // if (data.status > 300) {
            //     reset({
            //         reception_date: data.reception_date ? dayjs(data.reception_date) : null,
            //         analysis_date: data.analysis_date ? dayjs(data.analysis_date) : null,
            //         max_response_date: data.max_response_date ? dayjs(data.max_response_date) : null,
            //         response_date: data.response_date ? dayjs(data.response_date) : null,
            //         chance: successChanceData.filter((item) => item.id === data.chance)[0]
            //     });
            // }
            // reset({
            //     // chance: successChanceData.filter((item) => item.id === data.chance)[0],
            //     reception_date: data.reception_date ? dayjs(data.reception_date) : null,
            //     analysis_date: data.analysis_date ? dayjs(data.analysis_date) : null
            //     // response_date: data.response_date ? dayjs(data.response_date ) : null
            // });
        }
    }, [data])

    useEffect(() => {
        checkSaleInputsValidity();
    }, [saleValues])

    useEffect(() => {
        if (saleValues.length <= 4) {
            setSalesPopupOverflow('');
        }
        else {
            setSalesPopupOverflow('overflow-y-auto');
        }
    }, [saleValues])

    const checkSaleInputsValidity = () => {
        let invalid = false;

        for(const sale of saleValues) {
            if (data.status <= 300) {
                if (!sale.ressource) {
                    invalid = true;
                }
            }
            else {
                if (!sale.percent || !sale.ressource) {
                    invalid = true;
                }
            }
        }

        setSaleInputsInvalidity(invalid);
    }

    const handleAddSale = () => {
        if (saleValues.length < 10) {
            setSaleValues((prevState) => [...prevState, {id: Math.random(), percent: '', ressource: null}]);
        }
    }

    const handleRemoveSale = (id) => {
        setSaleValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handleSaleValueChange = (e, id, type) => {
        setSaleValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e}  : el));
    }

    const onSubmit = async (formData) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);
        setSaleInputsInvalidity(true);

        // Vérification de la cxohérence des pourcentage à partir de l'Offre remise
        if (data.status >= 300) {
            const validPercentValue = saleValues.map((sale) => parseInt(sale.percent) >= 0 ? true : false);
    
            if (validPercentValue.includes(false)) {
                setErrors(setCustomErrorMessage('Les valeurs négatives ne sont pas valides'));
                setSaleInputsInvalidity(false);
                setDisabled(false);
                setLoading(false);
    
                return;
            }
    
            const totalPercentValue = saleValues.reduce((a, b) => a + parseInt(b.percent), 0);
    
            if (totalPercentValue !== 100) {
                setErrors(setCustomErrorMessage('La somme des commissions doit être égale à 100'));
                setSaleInputsInvalidity(false);
                setDisabled(false);
                setLoading(false);
    
                return;
            }
        }

        let sales = [];

        if (data.status >= 300) {

            sales = saleValues.map((sale) => {
                return (
                    {
                        ressource_id: sale.ressource.id,
                        percent: parseInt(sale.percent)
                    }
                );
            })
        }
        else {
            // Si l'affaire est au statut "CdC demandé", les pourcentages des sales ne sont pas visibles ni modifiables.
            // Je répartis donc les pourcentages de chaque sale de manière à peu près égale pour que le total soit égal
            // à 100 et que le backend ne plante pas.

            const baseValue = Math.floor(100 / saleValues.length);
            const remainder = 100 % saleValues.length;

            const valuesArray = Array(saleValues.length).fill(baseValue);

            for (let i = 0; i < remainder; i++) {
                valuesArray[i] += 1;
            }

            sales = saleValues.map((sale, index) => {
                return (
                    {
                        ressource_id: sale.ressource.id,
                        percent: valuesArray[index]
                    }
                );
            })
        }
        let updatedDeal;

        if (data.status === 100) {
            updatedDeal = {
                sales: sales.reverse(),
                reception_date: standardFormat(formData.reception_date)
            }
        }

        if (data.status === 150) {
            updatedDeal = {
                sales: sales.reverse(),
                reception_date: standardFormat(formData.reception_date),
                analysis_date: standardFormat(formData.analysis_date)
            }
        }

        if (data.status === 200) {
            updatedDeal = {
                sales: sales.reverse(),
                reception_date: standardFormat(formData.reception_date),
                analysis_date: standardFormat(formData.analysis_date),
                max_response_date: standardFormat(formData.max_response_date)

            }
        }

        if (data.status >= 300) {
            updatedDeal = {
                sales: sales.reverse(),
                reception_date: standardFormat(formData.reception_date),
                analysis_date: standardFormat(formData.analysis_date),
                max_response_date: standardFormat(formData.max_response_date),
                response_date: standardFormat(formData.response_date),
                chance: formData.chance.id
            }
        }

        const result = await DealActions.updateDeal(userData, updatedDeal, data.id);
        
        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshData();
            setView(false);
            notify('Les informations ont été mis à jour');
        }

        setLoading(false);
        setDisabled(false);
        setSaleInputsInvalidity(false);

    }

    const filterOutAlreadySelected = (options, selected) => {

        if (!options) return;

        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.id === obj2?.ressource?.id
            )
        );

        return filteredArray;
    };

    return (
        <ContainerCard
            title={data.name}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex items-start justify-start px-4 mb-7">
                <div>
                    <div className={`flex justify-start pl-2 ${blueStyle ? "text-gradient-blue" : "text-gradient"} w-[100%] pb-2 pt-4 mb-6 border-b border-gray-200`}>
                    Statut & Équipe Commerciale
                    </div>
                    {
                        <div className="w-full pb-3 border-b border-gray-200">
                            <div className='flex w-full pb-2'>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({
                                        field: {onChange, ref, value},
                                        fieldState: {error}
                                    }) => (
                                        <SelectInput
                                            label='Statut'
                                            width='w-[810px]'
                                            placeholder='Statut'
                                            inputRef={ref}
                                            error={error}
                                            value={statusData.find((item) => item.id === data.status)}
                                            options={statusData}
                                            onChange={onChange}
                                            labelKeys={['name']}
                                            required={true}
                                            disabled={true}
                                            labelWidth='w-40'
                                            blueStyle={blueStyle}
                                        />
                                    )}
                                />
                            </div>
                            <div className='w-full'>
                                {
                                    saleValues.map((sale, index) => {
                                        return (
                                            <div
                                                className='flex w-full'
                                                key={sale.id}
                                            >
                                                <div className='w-full flex flex-row justify-between  mb-1.5'>
                                                    <div className='flex w-full'>
                                                        <SelectInput
                                                            label="Commercial"
                                                            placeholder='Choissisez un commercial'
                                                            width={
                                                                index === 0 && data.status < 300 ? 'w-[810px]' :
                                                                    index === 0 && data.status >= 300 ? "w-[735px]" :
                                                                        index !== 0 && data.status < 300 ? "w-[780px]" : 'w-[680px]'}
                                                            value={sale.ressource}
                                                            options={filterOutAlreadySelected(allUsers?.options, saleValues)}
                                                            onChange={(e) => handleSaleValueChange(e, sale.id, 'ressource')}
                                                            labelKeys={['first_name', 'last_name']}
                                                            disabled={disabled}
                                                            fetchFunction={handleGetAllUsers}
                                                            hasNextPage={allUsers?.hasNextPage}
                                                            isSearchable={true}
                                                            loading={!allUsers}
                                                            blueStyle={blueStyle}
                                                            labelWidth='w-40'
                                                        />
                                                        {data.status >= 300 ?
                                                            <div className="ml-3">
                                                                <TextInput
                                                                    label=''
                                                                    onChange={(e) => handleSaleValueChange(e, sale.id, 'percent')}
                                                                    value={sale.percent}
                                                                    error={null}
                                                                    required={true}
                                                                    disabled={disabled}
                                                                    placeholder="%"
                                                                    type="number"
                                                                    width='w-16'
                                                                    color={blueStyle ? 'blue' : 'orange'}
                                                                />
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div className='flex justify-between'>
                                                        {
                                                            index === 0 ?
                                                                null
                                                                : <button
                                                                    onClick={() => handleRemoveSale(sale.id)}
                                                                    disabled={disabled}
                                                                    className="flex items-center justify-center bg-transparent"
                                                                >
                                                                    <Tooltip title='Supprimer'>
                                                                        <div>
                                                                            <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                                        </div>
                                                                    </Tooltip>
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <div className='w-full flex items-center  mb-6'>
                                    <Tooltip title='Ajouter une ressource'>
                                        <button
                                            disabled={!(saleValues.length < 10)}
                                            onClick={handleAddSale}
                                            className={`whitespace-nowrap cursor-pointer text-sm ${blueStyle ? "text-gradient-blue" : "text-gradient"} ${saleValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"}`}
                                        >
                                            <p className='hover:underline'>Ajouter une ressource</p>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            <div className="flex flex-row justify-between px-4 pt-2 pb-6">
                <div className='flex flex-col'>
                    <div className='mb-1'>
                        <Controller
                            name="reception_date"
                            control={control}
                            rules={{required: {
                                value: true,
                                message: 'Veuillez selectionner une date'
                            }}}
                            render={({
                                field: {onChange, value},
                                fieldState: {error}
                            }) => (
                                <DateInput
                                    label={'Réception du CDC'}
                                    onChange={onChange}
                                    value={frenchFormat(value)}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                    {data.status >= 200 ?
                        <div className='mb-1'>
                            <div>
                                <Controller
                                    name="max_response_date"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez selectionner une date'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <DateInput
                                            labelWidth='w-[200px]'
                                            label={"Date limite de réponse"}
                                            onChange={onChange}
                                            value={frenchFormat(value)}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        : null}
                    {
                        data.status >= 300 ?
                            <Controller
                                name="chance"
                                control={control}
                                rules={{required: {
                                    value: true,
                                    message: 'Veuillez saisir la chance de réussite'
                                }}}
                                render={({
                                    field: {onChange, ref, value},
                                    fieldState: {error}
                                }) => (
                                    <SelectInput
                                        label='Espérance de réussite'
                                        placeholder='Espérance de réussite'
                                        inputRef={ref}
                                        error={error}
                                        value={value}
                                        options={successChanceData}
                                        onChange={onChange}
                                        labelKeys={['name']}
                                        required={true}
                                        disabled={disabled}
                                        labelWidth='w-40'
                                        blueStyle={blueStyle}
                                    />
                                )}
                            />
                            : null
                    }
                </div>
                <div className='flex flex-col'>
                    {data.status >= 150 ?
                        <div className='flex flex-col'>
                            <div className='mb-1'>
                                <Controller
                                    name="analysis_date"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez selectionner une date'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <DateInput
                                            label={"Date limite d'analyse"}
                                            onChange={onChange}
                                            value={frenchFormat(value)}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                            </div>
                        </div> : null}
                    {data.status >= 300 ?
                        <div className='flex flex-col'>
                            <div>
                                <Controller
                                    name="response_date"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez selectionner une date'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <DateInput
                                            label={"Date de réponse"}
                                            onChange={onChange}
                                            value={frenchFormat(value)}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                            </div>
                        </div> : null}
                </div>
            </div>
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => setView(false)}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={'Sauvegarder'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid || saleInputsInvalidity || saleValues.length < 1}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default SalesPopup;
